<template>
  <div v-if="handlePage">
    <div class="form-wizard-back">
      <b-button
        variant="outline-primary"
        size="sm"
        @click="routeToEventList()"
        class="wizard-back v2-back"
      >
        <feather-icon icon="ArrowLeftIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
      </b-button>
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        @on-change="changeNxtBtn($event)"
        :class="
          isAddPolygon
            ? 'wizard-nav-pills-step-2  mb-3 rule-page'
            : 'mb-3 rule-page'
        "
        @on-complete="setUserConfigurations"
      >
        <!-- accoint details tab -->
        <tab-content title="" :before-change="validationFormRule">
          <b-row>
            <b-col sm="3"></b-col>
            <b-col sm="6" class="multi-select-wh">
              <validation-observer
                ref="selectRuleValidation"
                #default="{ invalid }"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Select rule"
                  rules="required"
                >
                  <b-form-group :label="$t('rule.SelectRules')">
                    <v-select
                      id="vue-select"
                      v-model="selectedRule"
                      :options="rules"
                      :reduce="(option) => option.id"
                      label="rule_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </validation-observer>
            </b-col>
          </b-row>
        </tab-content>
        <!-- personal details tab -->
        <tab-content
          v-if="items.length != 0"
          title=""
          :before-change="validationFormRules"
        >
          <div>
            <b-container fluid>
              <validation-observer
                ref="selectRuleValidations"
                #default="{ invalid }"
              >
                <b-row clas="my-1" v-for="item in items" :key="item.id">
                  <b-col sm="12">
                    <b-form-group v-if="!item.component">
                      <label for="basic-password">{{
                        item.label_description
                      }}</label>
                      <b-input-group>
                        <validation-provider
                          v-if="!item.component"
                          #default="{ errors }"
                          :name="item.label_description"
                          rules="required"
                        >
                          <b-form-input
                            :type="item.data_type"
                            v-model="item.value"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-input-group>
                    </b-form-group>
                    <div v-if="item.component">
                      <Map
                        :setMapCoordinates="setMapCoordinates"
                        :data="mapCoordinates"
                        :ismapLoad="ismapLoad"
                        :isValidCoordinates="isValidCoordinates"
                        :forceLoad="true"
                      />
                    </div>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-container>
          </div>
        </tab-content>
        <!-- address  -->
        <tab-content title="">
          <div class="newcard mb-1">
            <div class="d-flex">
              <b-form-checkbox
                v-model="selectedActions.all"
                @change="(e) => handleCheckBox('all', null)"
              >
                {{ $t("EditRule.All") }}
              </b-form-checkbox>
            </div>
          </div>
          <div v-for="action in notificationActions">
            <div class="newcard mb-1">
              <div class="d-flex">
                <b-form-checkbox
                  v-model="selectedActions[action].selected"
                  v-b-toggle="action"
                  @change="(e) => handleCheckBox(action, 'selected')"
                >
                  {{ action }}</b-form-checkbox
                >
              </div>
              <b-collapse
                :id="action"
                :visible="selectedActions[action].selected"
                class="mt-2"
                v-model="selectedActions[action].selected"
              >
                <b-card class="border mb-0 d-flex justify-content-between cmr">
                  <div class="d-flex justify-content-between mb-1">
                    <div>
                      <b-form-checkbox
                        v-model="selectedActions[action].all"
                        @change="(e) => handleCheckBox(action, 'all')"
                      >
                        {{ $t("EditRule.Sendalerttoall") }}
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mb-1">
                    <div>
                      <b-form-checkbox
                        v-model="selectedActions[action].team"
                        @change="(e) => handleCheckBox(action, 'team')"
                      >
                        {{ $t("EditRule.SendtoTeams") }}</b-form-checkbox
                      >
                    </div>
                    <div>
                      <b-avatar
                        variant="primary"
                        v-for="people in selectedActions[action].team_data
                          .lists"
                        :key="people.id"
                        :text="people"
                        size="25"
                      />
                      <b-avatar
                        variant="primary"
                        v-if="selectedActions[action].team"
                        size="25"
                      >
                        <feather-icon
                          class="pointer"
                          v-b-tooltip.hover.v-primary
                          :title="$t('tooTip.selectTeam')"
                          icon="PlusIcon"
                          @click="handleTeamModel(action)"
                        />
                      </b-avatar>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div>
                      <b-form-checkbox
                        v-model="selectedActions[action].people"
                        @change="(e) => handleCheckBox(action, 'people')"
                      >
                        {{
                          $t("EditRule.SendtoSpecificPeoples")
                        }}</b-form-checkbox
                      >
                    </div>
                    <div>
                      <b-avatar
                        v-for="people in selectedActions[action].people_data
                          .lists"
                        variant="primary"
                        :text="people"
                        :key="people.id"
                        size="25"
                      >
                      </b-avatar>
                      <b-avatar
                        variant="primary"
                        v-if="selectedActions[action].people"
                        size="25px"
                      >
                        <feather-icon
                          class="pointer"
                          v-b-tooltip.hover.v-primary
                          :title="$t('tooTip.selectPeople')"
                          icon="PlusIcon"
                          @click="handlePeopleModel(action)"
                        />
                      </b-avatar>
                    </div>
                  </div>
                </b-card>
              </b-collapse>
            </div>
          </div>
          <b-modal
            id="team_people"
            class="custom-size-modal"
            size="md"
            centered
            no-close-on-esc
            no-close-on-backdrop
            :hide-footer="hideFooter"
          >
            <div class="d-flex justify-content-center">
              <b-card-title title-tag="h2" class="title font-weight-bold mb-0">
                <div>Team peoples</div>
              </b-card-title>
            </div>
            <b-row class="mt-2 d-flex">
              <b-col lg="6">
                <b-form-group
                  label="Search Team People"
                  label-for="SearchTeamPeople"
                >
                  <b-form-input
                    id="SearchTeamPeople"
                    placeholder="Search Team People"
                    v-model="teamPeoplesPagination.userSearch"
                    @input="handleTeamPeopleModel(roleId)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :items="teamPeoples"
              :fields="fields"
              striped
              responsive
              class="mt-1 specific-table-team-people table-border"
            >
              <template #cell(Name)="row">
                <b-col md="12">
                  <strong>{{ row.item.user_name }}</strong>
                </b-col>
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end">
              <b-pagination
                v-model="teamPeoplesPagination.currentPage"
                :total-rows="teamPeoplesPagination.count"
                :per-page="teamPeoplesPagination.pageSize"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
            <div class="demo-spacing-0 d-flex justify-content-center">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mt-1"
                @click="handleClose('team_people')"
              >
                Close
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="specific_team"
            class="custom-size-modal"
            size="md"
            centered
            no-close-on-esc
            no-close-on-backdrop
            :hide-footer="hideFooter"
          >
            <div class="d-flex justify-content-center">
              <b-card-title title-tag="h2" class="title font-weight-bold mb-0">
                <div>Send {{ actionMode }} to Specific Team</div>
              </b-card-title>
            </div>
            <!-- basic -->
            <b-row class="mt-2 d-flex">
              <b-col lg="6">
                <b-form-group label="Search Team" label-for="SearchTeam">
                  <b-form-input
                    id="SearchTeam"
                    placeholder="Search Team"
                    v-model="teamsPagination.roleSearch"
                    @input="handleTeamModel(actionMode)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :items="teams"
              :fields="team_fields"
              striped
              responsive
              class="mt-1 specific-table table-border"
            >
              <template #cell(Name)="row">
                <b-form-checkbox
                  class="pointer"
                  v-model="row.item.enable"
                  @change="(e) => saveTeamPrefrences(row)"
                >
                  <b-col md="12">
                    <b-avatar
                      variant="success"
                      :text="row.item.logo"
                      size="18px"
                    />
                    <strong>{{ row.item.team_name }}</strong>
                  </b-col>
                </b-form-checkbox>
              </template>
              <template #cell(people)="data">
                <b-avatar-group size="25px" class="mt-50">
                  <b-avatar
                    v-for="people in data.item.team_peoples.lists"
                    variant="primary"
                    :text="people.name"
                    :key="people.id"
                  />
                  <b-avatar variant="primary">
                    <feather-icon
                      v-b-tooltip.hover.v-primary
                      :title="$t('tooTip.viewTeamPeople')"
                      icon="PlusIcon"
                      @click="handleTeamPeopleModel(data.item.id)"
                    />
                  </b-avatar>
                </b-avatar-group>
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end">
              <b-pagination
                v-model="teamsPagination.currentPage"
                :total-rows="teamsPagination.count"
                :per-page="teamsPagination.pageSize"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
            <div class="demo-spacing-0 d-flex justify-content-center">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mt-1"
                @click="handleClose('specific_team')"
              >
                Close
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="specific_people"
            class="custom-size-modal"
            size="md"
            centered
            no-close-on-esc
            no-close-on-backdrop
            :hide-footer="hideFooter"
          >
            <div class="d-flex justify-content-center">
              <b-card-title title-tag="h2" class="title font-weight-bold mb-0 ">
                <div class="mt-2">Send {{ actionMode }} to Specific People</div>
              </b-card-title>
            </div>
            <!-- basic -->
             <div class="size-h">
            <b-row class="mt-2 d-flex">
              <b-col lg="6">
                <b-form-group label="Search Name" label-for="SearchName">
                  <b-form-input
                    id="SearchName"
                    placeholder="Search Name"
                    v-model="peoplesPagination.userSearch"
                    @input="handlePeopleModel(actionMode)"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Search role" label-for="SearchRole">
                  <b-form-input
                    id="SearchRole"
                    placeholder="Search Role"
                    v-model="peoplesPagination.roleSearch"
                    @input="handlePeopleModel(actionMode)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :items="peoples"
              :fields="fields"
              striped
              responsive
              class="mt-1 specific-table-people table-border"
            >
              <template #cell(Name)="row">
                <b-form-checkbox
                  class="pointer"
                  v-model="row.item.enable"
                  @change="(e) => savePeoplePrefrences(row)"
                >
                  <b-col md="12">
                    <b-avatar
                      variant="success"
                      :text="row.item.logo"
                      size="18px"
                    />
                    <strong>{{ row.item.user_name }}</strong>
                  </b-col>
                </b-form-checkbox>
              </template>
            </b-table>
            <div class="demo-spacing-0 d-flex justify-content-end">
              <b-pagination
                v-model="peoplesPagination.currentPage"
                :total-rows="peoplesPagination.count"
                :per-page="peoplesPagination.pageSize"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
            <div class="demo-spacing-0 d-flex justify-content-center">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mt-1"
                @click="handleClose('specific_people')"
              >
                Close
              </b-button>
            </div>
            </div>
          </b-modal>
        </tab-content>
      </form-wizard>
      <Loader :show="showLoading" />
    </div>
  </div>
  <div v-else>
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">{{ $t("rule.EventNotAvailableTXT") }}</h2>
          <p class="mb-2">
            {{ $t("rule.EventNotAvailableMSG") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import vBTooltip from "vue-ripple-directive";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Map from "./Map.vue";
import RuleService from "@/libs/api/rule-service";
import Loader from "@/layouts/components/Loader.vue";
import { required } from "@validations";
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BImg,
  BInputGroupAppend,
  BInputGroup,
  BPagination,
  BForm,
  BInputGroupPrepend,
  BFormCheckbox,
  BContainer,
  BTable,
  BMedia,
  BAvatar,
  BAvatarGroup,
  BCard,
  BFormRadio,
  VBToggle,
  BCollapse,
  BFormCheckboxGroup,
  BCardTitle,
  VBTooltip
} from "bootstrap-vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormRadio,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormFile,
    BImg,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BInputGroupPrepend,
    BFormCheckbox,
    Map,
    BContainer,
    Loader,
    BTable,
    BMedia,
    BAvatar,
    BAvatarGroup,
    BCard,
    BCollapse,
    VBToggle,
    BFormCheckboxGroup,
    BCardTitle,
    BPagination,
    VBTooltip
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      handlePage: true,
      selectedRule: "",
      showLoading: false,
      userRuleId: null,
      deviceId: null,
      unitId: null,
      items: [],
      ismapLoad: false,
      mapCoordinates: null,
      isAddPolygon: false,
      mapComponentIndex: -1,
      rules: [],
      actionMode: "",
      hideFooter: true,
      fields: ["Name", { key: "role", label: "Role" }],
      teamPeoplesFields: ["Name", { key: "role", label: "Role" }],
      team_fields: ["Name", { key: "people", label: "Peoples" }],
      notificationActions: ["MAIL", "SMS", "PUSH"],
      peoples: [],
      teams: [],
      teamPeoplesPagination: {
        pageSize: 10,
        count: 0,
        currentPage: 1,
        userSearch: ""
      },
      teamsPagination: {
        pageSize: 10,
        count: 0,
        currentPage: 1,
        roleSearch: ""
      },
      peoplesPagination: {
        pageSize: 10,
        count: 0,
        currentPage: 1,
        roleSearch: "",
        userSearch: ""
      },
      roleId: null,
      teamPeoples: [],
      selectedActions: {
        all: false,
        MAIL: {
          selected: false,
          all: false,
          team: false,
          people: false,
          team_data: {
            count: 0,
            lists: []
          },
          people_data: {
            lists: [],
            count: 1
          }
        },
        PUSH: {
          selected: false,
          all: false,
          team: false,
          people: false,
          team_data: {
            count: 0,
            lists: []
          },
          people_data: {
            count: 0,
            lists: []
          }
        },
        SMS: {
          selected: false,
          all: false,
          team: false,
          people: false,
          team_data: {
            count: 0,
            lists: []
          },
          people_data: {
            count: 0,
            lists: []
          }
        }
      },
      required
    };
  },
  watch: {
    selectedRule() {
      this.items = [];
      let ruleDetailsIndex = this.rules.findIndex((prop) => {
        if (prop.id == this.selectedRule) {
          return true;
        }
      });
      let ruleDetails = "";
      if (ruleDetailsIndex >= 0) {
        ruleDetails = this.rules[ruleDetailsIndex];
      }
      if (
        ruleDetails &&
        ruleDetails.variables &&
        ruleDetails.variables["_arr"]
      ) {
        this.items = ruleDetails.variables["_arr"];
        this.items.map((prop) => {
          if (prop.data_type == "Number") {
            prop.data_type = "number";
          }
        });
      }
    },
    "teamPeoplesPagination.currentPage"() {
      this.handleTeamPeopleModel(this.roleId);
    },
    "teamsPagination.currentPage"() {
      this.handleTeamModel(this.actionMode);
    },
    "peoplesPagination.currentPage"() {
      this.handlePeopleModel(this.actionMode);
    }
  },
  mounted() {
    this.deviceId = this.$route.query.deviceId;
    this.unitId = this.$route.query.unitId;
    this.getListOfApplicableRules();
  },
  methods: {
    handleClose(id) {
      this.$bvModal.hide(id);
      if (id == "specific_team") {
        this.getSelectedTeams(this.actionMode);
        this.teamPeoplesPagination["count"] = 0;
        this.teamPeoplesPagination["currentPage"] = 1;
        this.teamPeoplesPagination["role_search"] = "";
      } else if (id == "specific_people") {
        this.getSelectedPeoples(this.actionMode);
        this.peoplesPagination["count"] = 0;
        this.peoplesPagination["currentPage"] = 1;
        this.peoplesPagination["roleSearch"] = "";
        this.peoplesPagination["userSearch"] = "";
      } else if (id == "team_people") {
        this.teamPeoplesPagination["count"] = 0;
        this.teamPeoplesPagination["currentPage"] = 1;
        this.teamPeoplesPagination["userSearch"] = "";
      }
    },
    async saveTeamPrefrences(row) {
      try {
        this.showLoading = true;
        let response = await new RuleService().saveTeamPrefrences({
          role_id: row.item.id,
          enable: row.item.enable,
          channel: this.actionMode,
          user_device_action_id: this.userRuleId
        });
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "choice updated",
              icon: "InfoIcon",
              variant: "success"
            }
          });
        }
        this.showLoading = false;
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async savePeoplePrefrences(row) {
      try {
        this.showLoading = true;
        let response = await new RuleService().savePeoplesPrefrences({
          user_id: row.item.id,
          enable: row.item.enable,
          device_id: this.deviceId,
          channel: this.actionMode,
          user_device_action_id: this.userRuleId
        });
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "choice updated",
              icon: "InfoIcon",
              variant: "success"
            }
          });
        }
        this.showLoading = false;
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async handlePeopleModel(action) {
      this.actionMode = action;
      try {
        this.showLoading = true;
        let response = await new RuleService().getPeoples({
          role_search: this.peoplesPagination.roleSearch,
          user_search: this.peoplesPagination.userSearch,
          page: this.peoplesPagination.currentPage,
          page_size: this.peoplesPagination.pageSize,
          channel: action,
          user_device_action_id: this.userRuleId
        });
        if (response && response.data) {
          this.peoples = response.data.lists;
          this.peoplesPagination["count"] =
            response.data.pagination.total_records;
          this.peoplesPagination["currentPage"] = response.data.pagination.page;
          this.$bvModal.show("specific_people");
        }
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async handleTeamPeopleModel(roleId) {
      this.roleId = roleId;
      try {
        this.showLoading = true;
        let response = await new RuleService().getTeamPeoples({
          role_id: roleId,
          user_search: this.teamPeoplesPagination.userSearch,
          page: this.teamPeoplesPagination.currentPage,
          page_size: this.teamPeoplesPagination.pageSize,
          user_device_action_id: this.userRuleId
        });
        if (response && response.data) {
          this.teamPeoples = response.data.lists;
          this.teamPeoplesPagination["count"] =
            response.data.pagination.total_records;
          this.teamPeoplesPagination["currentPage"] =
            response.data.pagination.page;
          this.$bvModal.show("team_people");
        }
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async handleTeamModel(action) {
      this.actionMode = action;
      try {
        this.showLoading = true;
        let response = await new RuleService().getTeams({
          role_search: this.teamsPagination.roleSearch,
          page: this.teamsPagination.currentPage,
          page_size: this.peoplesPagination.pageSize,
          channel: action,
          user_device_action_id: this.userRuleId
        });
        if (response && response.data) {
          this.teams = response.data.lists;
          for (let team of this.teams) {
            team.team_peoples.lists.map((prop) => {
              prop.name = prop.name.split(" ")[0]
                ? prop.name.split(" ")[0].length > 1
                  ? prop.name.split(" ")[0][0] + prop.name.split(" ")[1][0]
                  : ""
                : "";
            });
            if (team.team_peoples.count > 4) {
              team.team_peoples.lists.push({
                name: `+${team.team_peoples.count - 4}`
              });
            }
          }
          this.teamsPagination["count"] =
            response.data.pagination.total_records;
          this.teamsPagination["currentPage"] = response.data.pagination.page;
          this.$bvModal.show("specific_team");
        }
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async handleCheckBox(action, property) {
      if (this.userRuleId == null) {
        await this.setUserConfigurations(false);
      }
      if (action == "all" && this.selectedActions["all"] == true) {
        for (let item of this.notificationActions) {
          if (this.selectedActions[item]["selected"] == false) {
            this.selectedActions[item]["selected"] = true;
            if (
              this.selectedActions[item]["team"] == false &&
              this.selectedActions[item]["people"] == false
            ) {
              this.selectedActions[item]["all"] = true;
            }
          }
        }
      } else if (action == "all" && this.selectedActions["all"] == false) {
        for (let item of this.notificationActions) {
          this.selectedActions[item]["selected"] = false;
          for (let param of ["all", "team", "people"]) {
            this.selectedActions[item][param] = false;
          }
        }
      } else if (
        this.notificationActions.indexOf(action) >= 0 &&
        ["all", "team", "people"].indexOf(property) >= 0
      ) {
        let obj = {
          all: ["team", "people"],
          people: ["all", "team"],
          team: ["all", "people"]
        };
        if (this.selectedActions[action][property] == true) {
          this.selectedActions[action]["selected"] = true;
          for (let param of obj[property]) {
            this.selectedActions[action][param] = false;
          }
        } else if (this.selectedActions[action][property] == false) {
          this.selectedActions[action]["selected"] = false;
        }
      } else if (
        this.notificationActions.indexOf(action) >= 0 &&
        property == "selected"
      ) {
        if (this.selectedActions[action][property] == true) {
          if (
            this.selectedActions[action]["team"] == false &&
            this.selectedActions[action]["people"] == false
          ) {
            this.selectedActions[action]["all"] = true;
          }
        } else {
          for (let param of ["all", "team", "people"]) {
            this.selectedActions[action][param] = false;
          }
        }
      }
      let selectedCount = 0;
      for (let item of this.notificationActions) {
        if (this.selectedActions[item]["selected"] == true) {
          selectedCount++;
          if (
            this.selectedActions[item]["team"] == false &&
            this.selectedActions[item]["people"] == false
          ) {
            this.selectedActions[item]["all"] = true;
          }
        }
        if (selectedCount == 3) {
          this.selectedActions.all = true;
        } else {
          this.selectedActions.all = false;
        }
      }
    },
    async getSelectedPeoples(action) {
      try {
        this.showLoading = true;
        let response = await new RuleService().getPeoples({
          page: 1,
          page_size: 5,
          channel: action,
          selected_peoples: true,
          user_device_action_id: this.userRuleId
        });
        this.updateSelectedActions(response, action, "people", "user_name");
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    updateSelectedActions(response, action, param, key) {
      if (response && response.data) {
        this.selectedActions[action][`${param}_data`]["lists"] =
          response.data.lists;
        this.selectedActions[action][`${param}_data`]["count"] =
          response.data.count;
        this.updateSelectedTeamAndPeopledata(action, param, key);
      }
    },
    async getSelectedTeams(action) {
      try {
        this.showLoading = true;
        let response = await new RuleService().getTeams({
          page: 1,
          page_size: 5,
          selected_teams: true,
          channel: action,
          user_device_action_id: this.userRuleId
        });
        this.updateSelectedActions(response, action, "team", "team_name");
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    updateSelectedTeamAndPeopledata(action, param, key) {
      this.selectedActions[action][`${param}_data`].lists.map((prop, index) => {
        this.selectedActions[action][`${param}_data`].lists[index] = prop[
          key
        ].split(" ")[0]
          ? prop[key].split(" ").length > 1
            ? prop[key].split(" ")[0][0] + prop[key].split(" ")[1][0]
            : prop[key].split(" ")[0][0]
          : "";
      });
      if (this.selectedActions[action][`${param}_data`].count > 4) {
        this.selectedActions[action][`${param}_data`].lists.push(
          `+${this.selectedActions[action][`${param}_data`].count - 4}`
        );
      }
    },
    routeToRuleConfigurations() {
      this.$router.push({
        name: "rules",
        query: { deviceId: this.deviceId, unitId: this.unitId }
      });
    },
    async changeNxtBtn(e) {
      if (e === 1) {
        this.isAddPolygon = false;
      } else if (e === 0 && this.mapCoordinates) {
        this.isAddPolygon = false;
      }
    },
    isValidCoordinates() {
      if (this.mapCoordinates) {
        this.isAddPolygon = false;
      } else {
        this.isAddPolygon = true;
      }
    },
    validationFormRule() {
      return new Promise((resolve, reject) => {
        this.$refs.selectRuleValidation.validate().then((success) => {
          if (success) {
            this.ismapLoad = true;
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormRules() {
      return new Promise((resolve, reject) => {
        this.$refs.selectRuleValidations.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    routeToEventList() {
      this.$router.push({
        name: "event-list",
        query: { deviceId: this.deviceId, unitId: this.unitId }
      });
    },
    async getListOfApplicableRules() {
      try {
        this.showLoading = true;
        let response = await new RuleService().getListOfApplicableRules({
          unit_id: this.unitId,
          id: this.deviceId
        });
        if (response && response.data) {
          this.rules = response.data.device_rules;
          if (this.rules.length == 0) {
            this.handlePage = false;
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async setUserConfigurations(submitAction) {
      try {
        this.showLoading = true;
        let response = await new RuleService().setUserConfigurations({
          device_action_id: this.selectedRule,
          device_id: this.deviceId,
          unit_id: this.unitId,
          actions: this.selectedActions,
          enable: submitAction == false ? false : true,
          values: { _arr: this.items }
        });
        if (response && response.data) {
          if (submitAction == false) {
            this.userRuleId = response.data.user_device_action_id;
            this.showLoading = false;
            return;
          }
          this.$router.push({
            name: "event-list",
            query: { deviceId: this.deviceId, unitId: this.unitId }
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("rule.saveTitle"),
              text: this.$t("rule.save"),
              icon: "EditIcon",
              variant: "success"
            }
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    setMapCoordinates(p) {
      this.boundary = null;
      let array = [];
      if (p.fenceType == "polygon") {
        array = p.latlngs[0];
      } else if (p.fenceType == "circle") {
        this.boundary = p.center;
      } else if ((p.fenceType = "rectangle")) {
        array = p.latlngs[0];
      }
      for (let prop of array) {
        if (this.boundary) {
          this.boundary.push([prop.lat, prop.lng]);
        } else {
          let temp = [prop.lat, prop.lng];
          this.boundary = [temp];
        }
      }
      this.mapComponentIndex = this.items.findIndex((prop) => {
        return prop.component == "MAP";
      });
      if (this.mapComponentIndex >= 0) {
        this.items[this.mapComponentIndex]["value"] = this.boundary;
        this.items[this.mapComponentIndex]["fenceType"] = p.fenceType;
        if (p.fenceType == "circle") {
          this.items[this.mapComponentIndex]["radius"] = p.radius;
        } else {
          delete this.items[this.mapComponentIndex]["radius"];
        }
        this.mapCoordinates = this.items[this.mapComponentIndex];
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "./index.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.leaflet-draw-draw-polyline {
  display: none !important;
}
.leaflet-draw-draw-marker {
  display: none !important;
}
.wizard-back {
  position: absolute;
  z-index: 9;
  right: $px_15;
  top: $px_15;
  cursor: pointer;
}
.rule-page {
  .wizard-tab-content {
    min-height: 45vh !important;
  }
}
.table-border {
  border-radius: 5px;
}
</style>
